import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import React, { useState } from "react";
import ResponsiveDialog from "../components/ResponsiveDialog";
import { db } from "../firebase";

const WaitlistDialog = ({ open, onClose }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false); // Track submission status
  const theme = useTheme();

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await addDoc(collection(db, "waitlist"), {
        email,
        createdAt: serverTimestamp(),
      });
      setSuccess(true); // Mark as successful on completion
      setEmail("");
    } catch (error) {
      console.error("Error adding email to waitlist:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ResponsiveDialog
      open={open}
      onClose={onClose}
      title="Download"
      width="600px"
      anchor="bottom"
      zIndex={2100}
      fullHeight={true}
    >
      {success ? (
        <>
          <DialogTitle>Thank You!</DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              color: "white",
              textAlign: "left",
            }}
          >
            <DialogContentText color="white">
              Your email has been added to the waitlist. You'll hear from us
              soon!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={onClose}
              variant="contained"
              sx={{
                background: "linear-gradient(45deg, #ff4081, #ff8a80)", // Gradient button
                textTransform: "none",
                fontWeight: "600",
                height: "56px",
                width: { xs: "100%", sm: "100px" },
              }}
            >
              Close
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>Join the Waitlist</DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              color: "white",
            }}
          >
            <DialogContentText color="white">
              Sign up to receive updates and be notified when we launch.
              {/* This product is in development, could you help me to build it?
               - Who are they?
               - What really bothered them?
               - Open ended question - What's your biggest challenge right now?
               - Do they have a solution in mind?
               - How painful is this problem?
               - Did you try something else?
               - Thanks, we are launching soon, would you like to be notified?
               - Thanks for your time, you will receive an email (make them open it), meanwhile, follow us on ___. 
               - email should dive into personal story
                */}
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="email"
              label="Email Address"
              type="email"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              variant="standard"
              sx={{
                mb: 2,
                "& .MuiInput-underline:before": {
                  borderBottom: "2px solid #ff4081", // Pink underline color before interaction
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottom: "2px solid #ff4081", // Pink underline color on hover
                },
                "& .MuiInput-underline:after": {
                  borderBottom: "2px solid #ff4081", // Pink underline color after interaction
                },
              }}
              InputProps={{
                style: {
                  backgroundColor: "#333",
                  color: "#fff",
                },
              }}
              InputLabelProps={{
                style: {
                  color: "#fff",
                },
              }}
            />
          </DialogContent>
          <DialogActions>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column-reverse", sm: "row" },
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              <Button
                onClick={onClose}
                variant="text"
                sx={{
                  textTransform: "none",
                  fontWeight: "600",
                  height: "56px",
                  color: "white",
                  width: { xs: "100%", sm: "100px" },
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                variant="contained"
                sx={{
                  background: "linear-gradient(45deg, #ff4081, #ff8a80)", // Gradient button
                  textTransform: "none",
                  fontWeight: "600",
                  height: "56px",
                  width: { xs: "100%", sm: "100px" },
                }}
                disabled={!email || loading}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Submit"
                )}
              </Button>
            </Box>
          </DialogActions>
        </>
      )}
    </ResponsiveDialog>
  );
};

export default WaitlistDialog;
