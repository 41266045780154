import { ArrowBackIosNewRounded, Close } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";

const DrawerHeader = ({
  title,
  onClose,
  anchor = "bottom",
  showClose = true,
  showLogo = false,
  actions = null,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        position: "relative", // Added to position children absolutely within this Box.
        display: "flex",
        alignItems: "center", // Vertically aligns items in the center.
        padding: 2,
        background: "linear-gradient(135deg, #0f0f0f, #2b2b2b)", // Darker gradient
        color: "white",
        justifyContent:
          showClose || showLogo || actions ? "space-between" : "center",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        height: "69px",
        maxHeight: "69px",
        minHeight: "69px",
        boxSizing: "border-box",
      }}
    >
      {showClose && (
        <IconButton onClick={onClose}>
          {!isMobile && <Close sx={{color: "white"}} />}
          {isMobile && anchor === "bottom" && <Close sx={{color: "white"}} />}
          {isMobile && anchor === "right" && <ArrowBackIosNewRounded sx={{color: "white"}} />}
        </IconButton>
      )}

      {showLogo && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            zIndex: "2000",
          }}
        >
          <Box width={150}>
            {/* <Logo color="rgba(0, 0, 0, 0.87)" /> */}
          </Box>
        </Box>
      )}

      {/* Title container to help center the title text */}
      <Box
        sx={{
          mx: showLogo ? 0 : 1, // Adjust margin based on logo presence
          flexGrow: showLogo ? 1 : 0, // If logo is present, allow title to grow
          display: "flex",
          justifyContent: showLogo ? "flex-end" : "center",
        }}
      >
        <Typography variant="h6">{title}</Typography>
      </Box>

      {(showClose || actions) &&
        (actions ? (
          actions
        ) : (
          <IconButton style={{ pointerEvents: "auto", visibility: "hidden" }}>
            <Close sx={{color: "white"}} />
          </IconButton>
        ))}

      {/* Make puller bar */}
      {isMobile && anchor === "bottom" && showClose && (
        <Box
          sx={{
            position: "absolute",
            top: 8,
            left: "50%",
            transform: "translateX(-50%)",
            width: 50,
            height: 5,
            backgroundColor: "rgba(0, 0, 0, 0.12)",
            borderRadius: 2.5,
          }}
        />
      )}
    </Box>
  );
};

export default DrawerHeader;
