import { CheckCircle as CheckCircleIcon } from "@mui/icons-material";
import {
  Box,
  Container,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import MapDraw from "../map/MapDraw";

// Custom styling for the Features section
const FeaturesContainer = styled(Box)(({ theme }) => ({
  background: "linear-gradient(135deg, #1f1f1f, #3b3b3b)", // Dark gradient background
  color: "white",
}));

const DemoWindow = styled(Box)(({ theme }) => ({
  background: "rgba(255, 255, 255, 0.1)", // Semi-transparent background
  border: "2px solid #444", // Border for demo window
  padding: "5px", // Padding for the demo area
  textAlign: "center", // Centering content
  borderRadius: "20px", // Rounded corners
  width: "100%",
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.5)", // Slight shadow for depth
}));

// Array to hold the features
const features = [
  "Touch and cursor drawing supported.",
  "Quick integration with a single line of code.",
  "Customizable handlers for bounds and save events.",
  "Easily add buttons and other UI elements.",
  "Works seamlessly with React.js projects.",
];

// Component to render the FeatureSection
const FeatureSection = () => {
  return (
    <FeaturesContainer>
      <div id="features" />

      <Container sx={{ py: { xs: 4, sm: 10 } }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: {xs: "column-reverse", sm: "row",},
            flexWrap: "wrap",
            gap: 4,
          }}
        >
          <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
            <Typography
              variant="h2"
              align="left"
              sx={{
                fontWeight: "bold",
                fontSize: "4rem",
                marginBottom: "30px",
              }}
            >
              Key Features
            </Typography>

            <Divider sx={{ backgroundColor: "gray", marginBottom: "20px" }} />

            <Typography
              variant="h6"
              sx={{
                marginBottom: "20px",
              }}
            >
              Map Draw simplifies Google Maps integration with a single line of
              code and provides additional useful features:
            </Typography>

            <List>
              {features.map((feature, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <CheckCircleIcon
                      sx={{
                        color: "#ff4081", // Vibrant gradient for the icon
                        fontSize: "2rem", // Larger icon
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={feature} />
                </ListItem>
              ))}
            </List>
          </Box>

          <Box
            sx={{
              flex: 1,
            }}
          >
            <DemoWindow>
              <MapDraw />
            </DemoWindow>
          </Box>
        </Box>
      </Container>
    </FeaturesContainer>
  );
};

export default FeatureSection;
