import { Box } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import CTASection from "../sections/CTASection";
import FAQSection from "../sections/FAQSection";
import FeatureSection from "../sections/FeaturesSection";
import FooterSection from "../sections/FooterSection";
import HeaderSection from "../sections/HeaderSection";
import HeroSection from "../sections/HeroSection";
import PricingSection from "../sections/PricingSection";
import ProblemSection from "../sections/ProblemSection";

const LandingPage = () => {
  return (
    <Box sx={{ width: "100vw", overflow: "hidden" }}>
      <Helmet>
        <title>Map Draw - Draw Freehand on Google Maps within minutes</title>
        <meta
          name="description"
          content="Easily create freehand drawings on Google Maps in React.js. within minutes using MapDraw. Instead of configuring your own google maps, drop in our pre-built component and get up and running in mintutes."
        />
        <meta
          name="keywords"
          content="draw google maps, google maps api, Google Map Drawing freehand, Freehand Sketching On Google Maps, Draw Freehand Shapes on Google Maps in React.js | #1"
        />
        {/* Canonical URL to avoid duplicate content issues */}
        <link rel="canonical" href="https://map-draw.com" />
      
        {/* Open Graph (OG) tags for social media sharing */}
        <meta
          property="og:title"
          content="Map Draw - Draw Freehand on Google Maps within minutes"
        />
        <meta
          property="og:description"
          content="Map Draw makes freehand drawing on Google Maps in React.js easy and efficient. Learn how you can integrate it within minutes."
        />
        <meta property="og:url" content="https://map-draw.com" />
        <meta property="og:type" content="website" />
        {/* <meta
          property="og:image"
          content="https://map-draw.com/path/to/og-image.jpg"
        />{" "} */}
        {/* OG image for social sharing */}
        {/* Twitter Card for Twitter sharing */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Map Draw - Draw Freehand on Google Maps within minutes"
        />
        <meta
          name="twitter:description"
          content="Create freehand drawings on Google Maps in React.js with Map Draw. Get started within minutes!"
        />
        {/* <meta
          name="twitter:image"
          content="https://map-draw.com/path/to/twitter-image.jpg"
        />{" "} */}
        {/* Twitter image */}
        <meta name="twitter:url" content="https://map-draw.com" />
      </Helmet>

      {/* Landing Page Sections */}
      <HeaderSection />
      <HeroSection />
      <ProblemSection />
      <FeatureSection />
      <PricingSection />
      <FAQSection />
      <CTASection />
      <FooterSection />
    </Box>
  );
};

export default LandingPage;
