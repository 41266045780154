import { Box } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialDark } from "react-syntax-highlighter/dist/esm/styles/prism";

// Custom styling for the code block container
const CodeBlockContainer = styled(Box)(({ theme }) => ({
  background: "linear-gradient(135deg, #2b2b2b, #1a1a1a)", // Dark gradient for background
  padding: "10px", // Padding around the code block
  borderRadius: "20px", // Rounded corners
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.5)", // Slight shadow for depth
}));

const StyledCodeBlock = () => {
  const codeSnippet = `
import MapDraw from 'map-draw';

const MyComponent = () => {
  // Set up MapDraw with handlers
  const handleBoundsChange = (bounds) => console.log(bounds);
  const handleSave = () => console.log('Save clicked');
  
  return (
    <Box>

      <MapDraw 
        onBoundsChange={handleBoundsChange} 
        onSave={handleSave} 
        ...
      />

      <Button onClick={handleSave}>Save</Button>
    </Box>
  );
};
  `;

  return (
    <CodeBlockContainer>
      <SyntaxHighlighter
        language="javascript"
        style={materialDark}
        customStyle={{ margin: "0", borderRadius: "15px" }} // Removes margin
      >
        {codeSnippet}
      </SyntaxHighlighter>
    </CodeBlockContainer>
  );
};

export default StyledCodeBlock;
