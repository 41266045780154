import { Box, Button, Container, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { useDownload } from "../providers/DownloadProvider";
import { handleScroll } from "../services.js/navigationServices";

// Custom styling for the CTA section
const CTAContainer = styled(Box)(({ theme }) => ({
  background: "linear-gradient(135deg, #1f1f1f, #3b3b3b)", // Dark gradient background
  color: "white",
  textAlign: "center", // Centered text and elements
}));

const CTAButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main || "#ff4081", // Fallback color if undefined
  background: "linear-gradient(45deg, #ff4081, #ff8a80)", // Gradient button
  color: "white",
  padding: "15px 30px",
  borderRadius: "10px",
  textTransform: "none",
  fontWeight: "600",
  fontSize: "1rem",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  maxWidth: "200px",
  mx: "auto",
}));

const CTASection = () => {
  const theme = useTheme(); // Ensure theme is used in the correct context
  const { handleOpenDownload } = useDownload();

  return (
    <CTAContainer>
      <div id="cta" />

      <Container sx={{ py: { xs: 4, sm: 10 } }}>
        <Typography
          variant="h2"
          align="left"
          sx={{
            fontWeight: "bold",
            fontSize: "4rem",
            marginBottom: "30px",
          }}
        >
          Ready to Get Started?
        </Typography>

        <Typography
          variant="h6"
          align="left"
          sx={{
            marginBottom: "40px", // Additional spacing before the CTA button
          }}
        >
          Download the boilerplate code for just $9.59 and start creating your
          custom Google Maps integrations today!
        </Typography>

        <CTAButton
          variant="contained"
          theme={theme}
          onClick={() => {
            handleScroll("pricing");
          }}
        >
          Download Now
        </CTAButton>
      </Container>
    </CTAContainer>
  );
};

export default CTASection;
