import React, { createContext, useContext, useState } from "react";
import WaitlistDialog from "../dialogs/WaitlistDialog";

// Context for handling downloads and waitlist
export const DownloadContext = createContext();

// Provider component
export const DownloadProvider = ({ children }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  // Function to handle downloading and opening the dialog
  const handleOpenDownload = () => {
    setDialogOpen(true);
  };

  return (
    <DownloadContext.Provider value={{ handleOpenDownload }}>
      {children}
      <WaitlistDialog open={dialogOpen} onClose={() => setDialogOpen(false)} />
    </DownloadContext.Provider>
  );
};

export function useDownload() {
  return useContext(DownloadContext);
}
