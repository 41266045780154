import {
  AdsClick,
  ArrowDownward,
  Build,
  Code,
  Gesture,
  Storage,
} from "@mui/icons-material"; // Unique icons for each problem and a down arrow
import {
  Box,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

// Array of problems with estimated hours and unique icons
const problems = [
  {
    hours: "+ 1 hr",
    description: "manually setup and configure map container",
    icon: <Build />, // Wrench icon
  },
  {
    hours: "+ 2 hrs",
    description: "setup event handlers to track continuously",
    icon: <AdsClick />, // Layer icon
  },
  {
    hours: "+ 1 hr",
    description: "manage boundary refs to store current shape",
    icon: <Storage />, // Code icon
  },
  {
    hours: "+ 1 hr",
    description: "design intuitive drawing controls",
    icon: <Gesture />, // Gesture icon
  },
  {
    hours: "+ 1 hr",
    description: "optimize and compress shape output",
    icon: <Code />, // Code icon
  },
];

// Calculate the total hours from the problem data
const totalHours = problems.reduce((total, problem) => {
  const extractedHours = parseInt(problem.hours.split(" ")[1], 10); // Extract the number of hours
  return total + extractedHours;
}, 0);

// Styled component for the Problem section
const ProblemContainer = styled(Box)(({ theme }) => ({
  background: "linear-gradient(135deg, #3b3b3b, #1f1f1f)", // Dark gradient background
  color: "white", // Consistent text color
  textAlign: "left", // Align text to the left
}));

const ProblemSection = () => {
  return (
    <ProblemContainer>
      <Container sx={{ py: { xs: 4, sm: 10 } }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          {/* Problem title and subtext on the left */}
          <Box sx={{ flex: 0.5 }}>
            <Typography
              variant="h2"
              sx={{
                fontWeight: "bold",
                fontSize: "4rem",
                marginBottom: "30px",
              }}
            >
              The Problem
            </Typography>

            <Typography
              variant="body1"
              align="left"
              sx={{
                color: "#f0f0f0", // Consistent font color
                marginBottom: "50px",
                fontSize: "1.2rem",
              }}
            >
              As you've found, there is no built in freeform drawing option for
              Google Maps. You tried developing your own and found it full of
              headaches. So you Googled it! And now you're in the right place.
            </Typography>
          </Box>

          {/* Problem list and total on the right */}
          <Box
            sx={{
              flex: 0.5,
              width: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <List>
              {problems.map((problem, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    {React.cloneElement(problem.icon, {
                      sx: { fontSize: "2rem", color: "orange" },
                    })}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                          color: "white",
                        }}
                      >
                        <span style={{ color: "orange" }}>{problem.hours}</span>{" "}
                        {/* Hours in orange */}
                        {` ${problem.description}`} {/* Problem description */}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>

            {/* Total hours displayed at the bottom */}
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                fontSize: "1.5rem",
                color: "orange", // Orange for the total hours
                mt: 4,
              }}
            >
              = {totalHours} hours{" "}
              <span style={{ color: "white" }}>of effort</span>{" "}
              {/* White for the text */}
            </Typography>
          </Box>
        </Box>

        {/* Suggesting an easier way with an arrow pointing down */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: "60px",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: "1.2rem",
              color: "white",
            }}
          >
            There's an easier way
          </Typography>
          <ArrowDownward
            sx={{
              color: "white", // White for the arrow
              fontSize: "2rem", // Larger for emphasis
            }}
          />
        </Box>
      </Container>
    </ProblemContainer>
  );
};

export default ProblemSection;
