import { Code, Map } from "@mui/icons-material";
import { Box, Button, Container, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import React from "react";
import StyledCodeBlock from "../components/StyledCodeBlock";
import { useDownload } from "../providers/DownloadProvider";
import { handleScroll } from "../services.js/navigationServices";

// // Animation for background fade-in
// const fadeIn = keyframes`
//   from {
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// `;

// Custom styles for the Hero section
const StyledHero = styled(Box)(({ theme }) => ({
  background: "linear-gradient(135deg, #2b2b2b, #1e1e1e)", // Dark gradient background
  color: "white",
  textAlign: "center",
  minHeight: "500px",
  // animation: `${fadeIn} 1s ease-in-out`,
}));

const CTAButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main || "#ff4081", // Fallback color if undefined
  background: "linear-gradient(45deg, #ff4081, #ff8a80)", // Gradient button
  color: "white",
  padding: "15px 30px",
  borderRadius: "10px",
  textTransform: "none",
  fontWeight: "600",
  fontSize: "1rem",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const HeroSection = () => {
  const theme = useTheme(); // Ensure theme is used in the correct context
  const { handleOpenDownload } = useDownload();

  return (
    <StyledHero>
      <div id="hero" />

      <Container sx={{ py: { xs: 4, sm: 14 } }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" }, // Responsive layout
            flexWrap: "wrap",
            alignItems: { xs: "flex-start", sm: "center" },
            justifyContent: "center",
            gap: "20px", // Consistent spacing
          }}
        >
          <Box sx={{ flex: 1, textAlign: "left" }}>
            <Typography
              variant="h1"
              align="left"
              sx={{
                fontWeight: "bold",
                fontSize: "4rem",
                marginBottom: "10px",
              }}
            >
              Draw Freehand on Google Maps
            </Typography>
            <Typography
              variant="h4"
              align="left"
              sx={{
                fontWeight: "bold",
                fontSize: "2rem",
                position: "relative",
                display: "inline-block",
                mb: "30px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  display: "inline-block",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    position: "relative",
                    alignItems: "center",
                    zIndex: "1",
                    gap: 1, 
                    background: "linear-gradient(180deg, #ff4081, #ff8a80)",
                    WebkitBackgroundClip: "text",
                    backgroundClip: "text",
                    color: "transparent",
                    padding: "5px 10px",
                    borderRadius: "8px",
                    transform: "rotate(-0.5deg)",
                  }}
                >
                  <Code sx={{color: "#ff4081", fontSize: "2.5rem"}} />
                  with one-line of code
                </Box>
                <div
                  style={{
                    content: "",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    zIndex: "0",
                    background: "white",
                    borderRadius: "8px",
                    transform: "rotate(-1deg)",
                  }}
                ></div>
              </div>
            </Typography>
            <Typography
              variant="body1"
              align="left"
              sx={{
                color: "#f0f0f0", // Consistent font color
                marginBottom: "50px",
                fontSize: "1.2rem",
              }}
            >
              Unlock Google Maps in React.js with just one line of code. Quickly
              integrate a robust freeform drawing solution witihin minutes!
            </Typography>
            <CTAButton
              theme={theme}
              onClick={() => {
                handleScroll("pricing");
              }}
            >
              <Map sx={{ marginRight: "10px" }} /> Get Started
            </CTAButton>
            <Typography align="left" variant="body2" sx={{ mt: 1 }}>
              Next 43 customers get 20% off!
            </Typography>
          </Box>

          <Box sx={{ flex: 1, textAlign: "center" }}>
            <StyledCodeBlock />
          </Box>
        </Box>
      </Container>
    </StyledHero>
  );
};

export default HeroSection;
