import { Discount, ShoppingCart } from "@mui/icons-material";
import { Box, Button, Container, Divider, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { useDownload } from "../providers/DownloadProvider";

// Custom styling for the Pricing section
const PricingContainer = styled(Box)(({ theme }) => ({
  background: "linear-gradient(135deg, #3b3b3b, #1f1f1f)", // Dark gradient background
  color: "white",
  textAlign: "center", // Centering the pricing card
}));

// Outer box with gradient border and rounded corners
const OuterBorder = styled(Box)(({ theme }) => ({
  borderRadius: "30px", // Ensures rounded corners
  padding: "4px", // This padding gives space for the border
  background: "linear-gradient(135deg, #ff4081, #ff8a80)", // Gradient border
  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Soft shadow for depth
  maxWidth: "400px",
  marginLeft: "auto",
  marginRight: "auto",
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.5)", // Slight shadow for depth
}));

// Inner box with solid background and rounded corners
const InnerCard = styled(Box)(({ theme }) => ({
  borderRadius: "30px", // Match rounded corners with outer box
  padding: "40px", // Padding for inner content
  textAlign: "center", // Center content
  backgroundColor: "#222", // Slightly lighter background for contrast
  color: "white", // Text color
  margin: "0 auto", // Center the card horizontally
}));

const PricingCard = styled(Box)(({ theme }) => ({
  border: "4px solid transparent", // Solid transparent border for base
  borderRadius: "30px", // Ensure rounded corners for both card and border
  borderImage: "linear-gradient(135deg, #ff4081, #ff8a80) 1", // Gradient border with slicing
  borderImageSlice: 1, // Ensures the gradient follows rounded corners
  color: "white",
  padding: "40px",
  textAlign: "center", // Aligning text
  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Soft shadow for a 3D effect
  maxWidth: "400px", // Set a fixed width for the card
  margin: "0 auto", // Center the card horizontally
}));

const CTAButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#ff4081", // Fallback color if undefined
  background: "linear-gradient(45deg, #ff4081, #ff8a80)", // Gradient button
  color: "white",
  padding: "15px 30px",
  borderRadius: "10px",
  textTransform: "none",
  fontWeight: "600",
  fontSize: "1rem",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const PricingSection = () => {
  const { handleOpenDownload } = useDownload();
  return (
    <PricingContainer>
      <div id="pricing" />

      <Container sx={{ py: { xs: 4, sm: 10 } }}>
        <Typography
          variant="h2"
          align="left"
          sx={{
            fontWeight: "bold",
            fontSize: "4rem",
            marginBottom: "30px",
          }}
        >
          Pricing
        </Typography>

        <Divider sx={{ backgroundColor: "gray", marginBottom: "20px" }} />

        <OuterBorder>
          <InnerCard>
            <Typography
              variant="h4"
              align="center"
              sx={{
                fontWeight: "bold",
                fontSize: "2rem",
                marginBottom: "30px",
              }}
            >
              MapDraw
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "center",
                alignItems: { xs: "center", sm: "baseline" },
                gap: 1,
                mb: 2,
              }}
            >
              {/* Crossed-out original price */}
              <Typography
                variant="h3"
                sx={{
                  fontWeight: "bold",
                  textDecoration: "line-through", // Crossed out
                  fontSize: "1.5rem", // Smaller font
                  opacity: 0.5,
                }}
              >
                $11.99
              </Typography>

              {/* Discounted price */}
              <Typography
                variant="h2"
                sx={{
                  fontWeight: "bold",
                }}
              >
                $9.59
              </Typography>

              {/* Discount label */}
              {/* <StyledChip
              icon={<Discount />}
              label="20% Off!" // Customizable label
            /> */}
            </Box>

            {/* Product description */}
            <Typography
              variant="body1"
              align="left"
              sx={{
                marginBottom: "30px",
              }}
            >
              Download the Map Draw boilerplate code. Includes the key
              components to integrate your Google Maps freehand drawing.
            </Typography>

            <CTAButton
              onClick={handleOpenDownload}
              variant="contained"
              startIcon={<ShoppingCart />}
              sx={{
                backgroundColor: "black",
                padding: "15px 30px", // Increased padding for a premium feel
                borderRadius: "8px", // Rounded corners
                textTransform: "none", // Consistency with other sections
                "&:hover": {
                  backgroundColor: "gray", // Hover effect for the button
                },
              }}
            >
              Get MapDraw
            </CTAButton>
          </InnerCard>
        </OuterBorder>
      </Container>
    </PricingContainer>
  );
};

export default PricingSection;
