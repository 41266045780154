import { Close, Download as DownloadIcon, Menu } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/system";
import React, { useState } from "react";
import { useDownload } from "../providers/DownloadProvider";

import logo from "../images/logo192.png";
import { handleScroll } from "../services.js/navigationServices";

// Custom styles for AppBar
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  color: "white",
  zIndex: 1301,
  boxShadow: "none",
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: "10px 20px",
}));

const CTAButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#ff4081", // Fallback color
  background: "linear-gradient(45deg, #ff4081, #ff8a80)", // Gradient button
  color: "white",
  padding: "15px 30px",
  borderRadius: "10px",
  textTransform: "none",
  fontWeight: "600",
  fontSize: "1rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: 1,
}));

const HeaderSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md")); // Check for mobile layout
  const [drawerOpen, setDrawerOpen] = useState(false); // State for mobile drawer
  const { handleOpenDownload } = useDownload();

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const mobileMenu = (
    <Drawer
      anchor="top"
      open={drawerOpen}
      onClose={toggleDrawer(false)}
      PaperProps={{
        style: {
          top: "80px",
          position: "absolute",
          width: "100%",
          zIndex: 0, // This makes sure the Drawer stays below the AppBar
        },
      }}
    >
      <Box
        role="presentation"
        onClick={toggleDrawer(false)}
        sx={{
          width: isMobile ? "100vw" : "250px",
          textAlign: "center",
          background: "linear-gradient(135deg, #1f1f1f, #3b3b3b)",
        }}
      >
        <Box display="flex" flexDirection="column" gap={2} p={2}>
          <Button
            sx={{ color: "white" }}
            onClick={() => {
              handleScroll("features");
              toggleDrawer(false)();
            }}
          >
            Features
          </Button>
          <Button
            sx={{ color: "white" }}
            onClick={() => {
              handleScroll("pricing");
              toggleDrawer(false)();
            }}
          >
            Pricing
          </Button>
          <Button
            sx={{ color: "white" }}
            onClick={() => {
              handleScroll("faq");
              toggleDrawer(false)();
            }}
          >
            FAQ
          </Button>
          <CTAButton
            onClick={() => {
              handleScroll("pricing");
            }}
            variant="contained"
            endIcon={<DownloadIcon />}
          >
            Download
          </CTAButton>
        </Box>
      </Box>
    </Drawer>
  );

  return (
    <>
      <StyledAppBar
        sx={{
          position: { xs: "sticky", md: "absolute" },
          background: {
            xs: "linear-gradient(135deg, #1f1f1f, #3b3b3b)",
            md: "transparent",
          },
          height: "80px",
        }}
      >
        <StyledToolbar>
          {isMobile ? (
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              alignItems="center"
            >
              <Box display="flex" gap={1} sx={{ alignItems: "center" }}>
                <IconButton
                  color="inherit"
                  onClick={() => handleScroll("home")}
                >
                  <img
                    src={logo} // Custom logo path
                    alt="MapDraw Logo"
                    style={{ width: "38px", height: "38px" }} // Set logo to 24x24px
                  />
                </IconButton>
                <Typography
                  variant="h2"
                  sx={{ fontSize: "1rem", fontWeight: "600" }}
                >
                  MapDraw
                </Typography>
              </Box>

              <IconButton onClick={toggleDrawer(!drawerOpen)}>
                {drawerOpen ? (
                  <Close sx={{ color: "white" }} />
                ) : (
                  <Menu sx={{ color: "white" }} />
                )}{" "}
                {/* Mobile menu icon */}
              </IconButton>
              {mobileMenu}
            </Box>
          ) : (
            <Box
              display="flex"
              alignItems="center"
              justifyContent={"space-between"}
              width={"100%"}
              gap={2}
            >
              <Box display="flex" gap={1} sx={{ alignItems: "center" }}>
                <IconButton
                  color="inherit"
                  onClick={() => handleScroll("home")}
                >
                  <img
                    src={logo} // Custom logo path
                    alt="MapDraw Logo"
                    style={{ width: "38px", height: "38px" }} // Set logo to 24x24px
                  />
                </IconButton>
                <Typography
                  variant="h2"
                  sx={{ fontSize: "1rem", fontWeight: "600" }}
                >
                  MapDraw
                </Typography>
              </Box>
              <Box display="flex" gap={2}>
                <Button
                  color="inherit"
                  sx={{ textTransform: "none" }}
                  onClick={() => handleScroll("features")}
                >
                  Features
                </Button>
                <Button
                  color="inherit"
                  onClick={() => handleScroll("pricing")}
                  sx={{ textTransform: "none" }}
                >
                  Pricing
                </Button>
                <Button
                  color="inherit"
                  onClick={() => handleScroll("faq")}
                  sx={{ textTransform: "none" }}
                >
                  FAQ
                </Button>
              </Box>
              <CTAButton
                onClick={() => {
                  handleScroll("pricing");
                }}
                variant="contained"
                endIcon={<DownloadIcon />}
              >
                Download
              </CTAButton>
            </Box>
          )}
        </StyledToolbar>
      </StyledAppBar>
    </>
  );
};

export default HeaderSection;
