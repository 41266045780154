// Handle button click to scroll and update URL
export const handleScroll = (sectionId) => {
  // Prevent default action if this is triggered by an event
  // event.preventDefault(); // Uncomment if handleScroll is called with an event argument

  const element = document.getElementById(sectionId);

  if (element) {
    const elementPosition =
      element.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }

  // Update the URL hash
  window.history.pushState({}, "", `#${sectionId}`);
};
