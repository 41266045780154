import { createTheme } from "@mui/material/styles";

// Define custom theme with dark backdrop and vibrant gradients
const theme = createTheme({
  palette: {
    mode: "dark", // Dark mode for the backdrop
    primary: {
      main: "#ff4081", // Vibrant pink
    },
    secondary: {
      main: "#00e676", // Vibrant green
    },
    background: {
      default: "#121212", // Dark background
      paper: "#1c1c1c", // Darker paper background
    },
    text: {
      primary: "#ffffff", // White text
      secondary: "#b0bec5", // Lighter text for secondary elements
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Default MUI fonts
    h1: {
      fontSize: "2.5rem", // Custom size for H1
      fontWeight: "bold",
    },
    h2: {
      fontSize: "2rem",
      fontWeight: "bold",
    },
    body1: {
      fontSize: "1rem", // Standard body font size
    },
  },
});

export default theme;
