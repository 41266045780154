import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

// Custom styling for the FAQ section
const FAQContainer = styled(Box)(({ theme }) => ({
  background: "linear-gradient(135deg, #3b3b3b, #1f1f1f)", // Dark gradient background
  color: "white",
  textAlign: "center", // Centered text for a clean look
}));

// Styling for the accordion
const CustomAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: "transparent", // Transparent to match the background
  color: "white",
  border: "1px solid #444", // Border for visual separation
  borderRadius: "8px", // Rounded corners
  boxShadow: "none", // No additional shadow
  "&:not(:last-child)": {
    marginBottom: "20px", // Space between accordions
  },
}));

// FAQ data with questions and answers
const faqs = [
  {
    question: "What is Map Draw?",
    answer:
      "Map Draw is a Google Maps component for React.js that allows you to create custom boundaries and shapes with a single line of code.",
  },
  {
    question: "How much does it cost?",
    answer:
      "The boilerplate code for Map Draw is available for $9.59. It includes the key components and documentation to help you get started.",
  },
  {
    question: "Is it compatible with other React components?",
    answer:
      "Yes, Map Draw is designed to integrate seamlessly with other React components, allowing you to build custom interfaces and functionality around it.",
  },
  {
    question: "Do I need a Google Maps API key?",
    answer:
      "Yes, a Google Maps API key is required to use Map Draw. The API key grants you access to Google Maps services and must be set up in your project.",
  },
];

const FAQSection = () => {
  return (
    <FAQContainer>
            <div id="faq" />

      <Container sx={{ py: { xs: 4, sm: 10 } }}>
        <Typography
          variant="h2"
          align="left"
          sx={{
            fontWeight: "bold",
            fontSize: "4rem",
            marginBottom: "30px",
          }}
        >
          Frequently Asked Questions
        </Typography>

        {faqs.map((faq, index) => (
          <CustomAccordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />} // Expand icon with white color
              aria-controls={`faq${index + 1}-content`} // Accessibility label
              id={`faq${index + 1}-header`} // Accessibility ID
            >
              <Typography variant="h6">{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1">{faq.answer}</Typography>
            </AccordionDetails>
          </CustomAccordion>
        ))}
      </Container>
    </FAQContainer>
  );
};

export default FAQSection;
