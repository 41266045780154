import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

// Custom styling for the footer
const FooterContainer = styled(Box)(({ theme }) => ({
  background: "linear-gradient(135deg, #3b3b3b, #1f1f1f)", // Dark gradient background
  color: "white",
  padding: "20px 0", // Padding for the footer
  textAlign: "center", // Centered text
}));

const FooterSection = () => {
  return (
    <FooterContainer>
      <Typography variant="body2">
        © {new Date().getFullYear()} Linked Lives. All rights reserved. | Terms
        of Service
      </Typography>
    </FooterContainer>
  );
};

export default FooterSection;
